import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Circle } from "iconoir-react";
import * as React from "react";
import { cn } from "utils/utils.ts";

// type RadioGroupProps = Omit<
// 	React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>,
// 	'type'
// > & {
// 	type?: string;
// };

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    iconClassName?: string;
  }
>(({ className, iconClassName, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "ring-offset-background focus-visible:ring-ring aspect-square size-5 shrink-0 rounded-full border-2 border-gray-600 transition-colors duration-150 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 enabled:hover:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-400",
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle
          className={cn("size-3 fill-current text-current", iconClassName)}
        />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
